<template>
    <div class="sm:h-28 md:h-24 w-full bg-gray98 rounded-lg shadow-lg px-2 py-2 " >
    <div class="flex justify-start items-center align-middle h-full gap-4">
       
        <div class="w-min">
            <svg width="62" height="62" viewBox="0 0 62 62" class="cursor-pointer rounded-md" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!px.person.profile">
            <rect width="62" height="62" fill="#D9D9D9"/>
            <g opacity="0.5">
                <path d="M6 58.1754C6 44.379 17.1837 33.1953 30.9801 33.1953C44.7765 33.1953 55.9603 44.379 55.9603 58.1754" fill="#696969"/>
                <path d="M30.9801 31.9446C38.6968 31.9446 44.9525 25.689 44.9525 17.9723C44.9525 10.2556 38.6968 4 30.9801 4C23.2634 4 17.0078 10.2556 17.0078 17.9723C17.0078 25.689 23.2634 31.9446 30.9801 31.9446Z" fill="#696969"/>
            </g>
            </svg>
            <div v-else class="w-16 h-16 " >
                <img
                    :src="px.person.profile"
                    class="cursor-pointer rounded-md w-16 h-16 cover" 
                    v-viewer
                >
            </div>
            <div class="-mt-5 ml-12 absolute cursor-pointer" >
                <svg xmlns="http://www.w3.org/2000/svg" @click="$emit('edit',$attrs.value)" class="h-7 w-7  text-primary-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
            </div>
        </div>
        <div class="grid grid-flow-row w-max">
                <div class="grid sm:grid-cols-1 md:grid-cols-12">
                    <div class="sm:col-start-1 sm:col-end-2 md:col-start-1 md:col-end-10">
                        <span class="text-terciary-500 font-sans sm:text-sm md:text-2xl text-3xl tracking-wider md:text-left">{{ px.person.fullname ? px.person.fullname : '' }}</span>
                    </div>
                    <div class="sm:col-start-1 sm:col-end-2 md:col-start-10 md:col-end-13">
                            <span class="text-terciary-500 font-sans sm:text-sm md:text-lg tracking-wider"> ° Exp #{{  px.person.code ? px.person.code : '' }}</span>
                    </div>
                    <div class="sm:col-start-1 sm:col-end-2 md:col-start-1 md:col-end-13">
                        <span class="font-sans tracking-wider text-balance sm:text-sm text-terciary-500">{{ px.person.age ? px.person.age : '0' }} años   -    Afecciones: {{ px.query.current_condition ? px.query.current_condition : '' }}</span>
                    </div>
                </div>
        </div>
        <div class="flex justify-end align-top items-start h-full text-gray-400 " >
            <svg @click="$router.push('/')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="cursor-pointer w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    props: {
        px: {
        type: Object,
        default: function(){return {}}
        }
  },
  data: function() {
    return {
        icons: ['mdi-square-edit'],
        transparent: 'rgba(255, 255, 255)',
    }
  }

}
</script>

<style scoped>

.v-card {
    transition: 0.9 .4s ease-in-out;
  }

  .v-card:not(.on-hover) {
    opacity: 0.9;
  }

  .show-btns {
    color: rgb(255, 255, 255) !important;
  }

</style>