<template>
  <div>
        <da-header-title :title="'Datos Personales'" class="shadow-md font-medium tracking-wider" rtlcornerlg>
          <template #actions>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn  @click="$emit('delete')" icon v-bind="attrs" v-on="on">       
                    <v-icon  color="#E7AC18">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn  @click="$emit('edit')" icon v-bind="attrs" v-on="on">       
                    <v-icon  color="#E7AC18">mdi-file-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
          </template>
        </da-header-title>
        <div class="px-5 py-5 bg-white shadow-md">
            <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5" >
                <da-text-field-primary
                    :label="'Apellidos'"
                    :value="px.person.surname"
                    v-model="px.person.surname"
                    text
                    :disabled="isReadOnly"
                />

                <da-text-field-primary
                    :label="'Nombre'"
                    :value="px.person.name"
                    v-model="px.person.name"
                    text
                    :disabled="isReadOnly"
                />
           </div>
           <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5" >
            <da-text-field-primary
                    :label="'Fecha de nacimiento'"
                    :value="px.person.birthday"
                    v-model="px.person.birthday"
                    v-mask="'##-##-####'"
                    text
                     :disabled="isReadOnly"
                />
                <da-text-field-primary
                    :label="'Edad'"
                    :value="px.person.age"
                    v-model="px.person.age"
                    number
                     :disabled="isReadOnly"
                />
            </div>
            <div class="grid grid-cols-1 md:grid-cols-1 px-1 py-1 gap-5" >
                <da-text-field-primary
                    :label="'Lugar de nacimiento'"
                    :value="px.address.place_birth"
                    v-model="px.address.place_birth"
                    text
                     :disabled="isReadOnly"
                />
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5" >
            <da-text-field-primary
                  :label="'Ocupación'"
                  :value="px.person.ocupation"
                  v-model="px.person.ocupation"
                  text
                   :disabled="isReadOnly"
              />
              <da-text-field-primary
                  :label="'RFC'"
                  :value="px.person.rfc"
                  v-model="px.person.rfc"
                  text
                   :disabled="isReadOnly"
              />
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 px-1 py-1 gap-5" >

            <da-text-field-primary
                  :label="'Lugar de residencia'"
                  :value="px.address.residence"
                  v-model="px.address.residence"
                  text
                   :disabled="isReadOnly"
              />
              <da-text-field-primary
                  :label="'Dirección'"
                  :value="px.address.direction"
                  v-model="px.address.direction"
                  text
                   :disabled="isReadOnly"
              />
              <da-text-field-primary
                  :label="'Código postal'"
                  :value="px.address.cp"
                  v-model="px.address.cp"
                  text
                   :disabled="isReadOnly"
              />

            </div>

          <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5" >

            <da-text-field-primary
                  :label="'Teléfono local'"
                  :value="px.person.local_telephone"
                  v-model="px.person.local_telephone"
                  v-mask="'(###)-###-####'"
                  text
                  :disabled="isReadOnly"
              />
              <da-text-field-primary
                  :label="'Celular:'"
                  :value="px.person.telephone"
                  v-model="px.person.telephone"
                  v-mask="'(###)-###-####'"
                  text
                   :disabled="isReadOnly"
              />

            </div>
            <div class="grid grid-cols-1 md:grid-cols-1 px-1 py-1 gap-5" >
                <da-text-field-primary
                    :label="'Motivo de consulta'"
                    :value="px.query.reason_consultation"
                    v-model="px.query.reason_consultation"
                    text
                     :disabled="isReadOnly"
                />
            </div>

          <div class="grid grid-cols-1 md:grid-cols-1 px-1 py-1 gap-5" >
            <da-text-area-primary
                :label="'Padecimiento actual'"
                :value="px.query.current_condition"
                v-model="px.query.current_condition"
                text
                 :disabled="isReadOnly"
                outlined
            />
        </div>

        <!-- <div class="grid grid-cols-1 md:grid-cols-4 px-1 py-1 gap-5" >

            <da-text-field-primary
                  :label="'Pulso:'"
                  :value="px.query.pulse"
                  v-model="px.query.pulse"
                  text
                   :disabled="isReadOnly"
              />
              <da-text-field-primary
                  :label="'Presión(SYS):'"
                  :value="px.query.blood_pressure"
                  v-model="px.query.blood_pressure"
                  text
                   :disabled="isReadOnly"
              />
              <da-text-field-primary
                  :label="'Oxígenación:'"
                  :value="px.query.oxygenation"
                  v-model="px.query.oxygenation"
                  text
                   :disabled="isReadOnly"
              />
              <da-text-field-primary
                  :label="'Día:'"
                  :value="px.query.dia"
                  v-model="px.query.dia"
                  v-mask="'####-##-##'"
                  text
                   :disabled="isReadOnly"
              >
             </da-text-field-primary>

            </div> -->
        </div>
        <!-- <da-header-title :title="'Antecedentes Heredero-Familiares'"  class="shadow-md font-medium tracking-wider" />
        <div class="px-5 py-5 bg-white shadow-md">
          <div class="flex justify-start items-center gap-5 align-middle">
          <span for="" class="text-gray-500">Madre</span>

          <v-checkbox
            v-model="mother.live"
            label="Viva"
            color="gray"
            hide-details
            class="mt-0 pt-0"
             :disabled="isReadOnly"
          ></v-checkbox>
          <v-checkbox
            v-model="mother.dead"
            label="Fallecida"
            color="gray"
            hide-details
            class="mt-0 pt-0"
             :disabled="isReadOnly"
          ></v-checkbox>

      </div>
      <div class="grid grid-cols-1 md:grid-cols-1 px-1 py-1 gap-5" >
        <da-text-field-primary 
                :label="'(Si es finada) Causa de muerte:'"
                :value="mother.death_cause"
                v-model="mother.death_cause"
                text
                :disabled="mother.live ? true : false || isReadOnly"
            />
            <da-text-field-primary 
                :label="'(Si aún vive) Enfermedades que padece:'"
                :value="mother.current_illness"
                v-model="mother.current_illness"
                text
                :disabled="mother.dead ? true : false || isReadOnly"
            />
        </div>
    </div>
    <div class="px-5 py-5 bg-gray-100 shadow-md">
        <div class="flex justify-start items-center gap-5 align-middle">
            <span for="" class="text-gray-500">Padre</span>

            <v-checkbox
              v-model="father.live"
              label="Vivo"
              color="gray"
              hide-details
              class="mt-0 pt-0"
               :disabled="isReadOnly"
              
            ></v-checkbox>
            <v-checkbox
              v-model="father.dead"
              label="Fallecido"
              color="gray"
              hide-details
              class="mt-0 pt-0"
               :disabled="isReadOnly"
            ></v-checkbox>

        </div>
        <div class="grid grid-cols-1 md:grid-cols-1 px-1 py-1 gap-5" >
          <da-text-field-primary 
                  :label="'(Si es finado) Causa de muerte:'"
                  :value="father.death_cause"
                  v-model="father.death_cause"
                  text
                  :disabled="father.live ? true : false || isReadOnly"
              />
              <da-text-field-primary 
                  :label="'(Si aún vive) Enfermedades que padece:'"
                  :value="father.current_illness"
                  v-model="father.current_illness"
                  text
                  :disabled="father.dead ? true : false || isReadOnly"
              />
          </div>
        </div>
        <div class="px-5 py-5 bg-white shadow-md">
      <div class="flex justify-start items-center gap-5 align-middle">
          <span for="" class="text-gray-500">Hermanos</span>
          <v-checkbox
            v-model="hasBrothers"
            label="Sin hermanos"
            color="gray"
            hide-details
            class="mt-0 pt-0"
             :disabled="isReadOnly"
          ></v-checkbox>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 px-1 py-1 gap-5" >
        <da-text-field-primary 
              :label="'Número de hermanos:'"
              :value="px.family_brothers.total"
              v-model.number="px.family_brothers.total"
              number
              :disabled="hasBrothers || isReadOnly"
          />
        <da-text-field-primary 
                :label="'Vivos:'"
                :value="px.family_brothers.live"
                v-model.number="px.family_brothers.live"
                number
                :disabled="hasBrothers || isReadOnly"
            />
            <da-text-field-primary 
                :label="'Fallecidos:'"
                :value="px.family_brothers.dead"
                v-model.number="px.family_brothers.dead"
                number
                :disabled="hasBrothers || isReadOnly"
            />
        </div>
        <div class="grid grid-cols-1 md:grid-cols-1 px-1 py-1 gap-5" >
            <da-text-field-primary 
                :label="'Nombre y edades:'"
                :value="px.family_brothers.name_age"
                v-model="px.family_brothers.name_age"
                text
                :disabled="hasBrothers || isReadOnly"
            />
        </div>
        <div class="grid grid-cols-1 md:grid-cols-1 px-1 py-1 gap-5" >
            <da-text-field-primary 
                :label="'Causas de muerte:'"
                :value="px.family_brothers.causes_death"
                v-model="px.family_brothers.causes_death"
                text
                :disabled="hasBrothers || isReadOnly"
            />
        </div>
        <div class="grid grid-cols-1 md:grid-cols-1 px-1 py-1 gap-5" >
            <da-text-field-primary 
                :label="'Enfermedades que padece o padecio:'"
                :value="px.family_brothers.diseases"
                v-model="px.family_brothers.diseases"
                text
                :disabled="hasBrothers || isReadOnly"
            />
        </div>
    </div>
    <div class="px-5 py-5 bg-gray-100 shadow-md">
      <div class="flex justify-start items-center gap-5 align-middle">
          <span for="" class="text-gray-500">Abuelos Paternos</span>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-1 px-1 py-1 gap-5" >
          <da-text-field-primary 
              :label="'Enfermedades:'"
              :value="grandfather.diseases"
              v-model="grandfather.diseases"
              text
              :disabled="isReadOnly"
          />
        </div>

      <div class="flex justify-start items-center gap-5 align-middle">
          <span for="" class="text-gray-500">Abuelos Maternos</span>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-1 px-1 py-1 gap-5" >
          <da-text-field-primary 
              :label="'Enfermedades:'"
              :value="grandmother.diseases"
              v-model="grandmother.diseases"
              text
              :disabled="isReadOnly"
          />
        </div>

    </div> -->
    <da-header-title :title="'Datos Patológicos'" class=" font-medium tracking-wider" />
    <div class="px-5 py-5">

      <div class="flex justify-start items-center gap-5 align-middle">
          <span for="" class="text-gray-500">¿Usted padece o ha padecido alguna de las siguientes enfermedades o malestares?</span>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-1 px-1 py-1 gap-5" >

       <div v-for="(item, idx) in px.pathological_data" :key="idx">
        <div class="px-5 py-2 border-2 border-gray-400 rounded-lg text-gray-500">
          <span>{{ item.description ? item.description : ''  }}</span>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
          <div v-for="(ch, idy) in item.child" :key="idy">
              <div  v-if="ch.unique" class="flex items-center gap-2">
                <v-checkbox
                    :label="ch.description"
                    color="gray"
                    hide-details
                    class="mt-0 pt-0"
                    v-model="ch.value"
                    :disabled="isReadOnly"
                  ></v-checkbox>
                  <small v-if=" ch.lower_description">{{ ch.lower_description ? ch.lower_description : '' }}</small>
              </div>
              <div v-else>
                <div class="flex items-center gap-2 text-gray-500">
                  {{ ch.description ?  ch.description : '' }}
                  <div  v-for="(opt, idt) in ch.options" :key="idt">
                    <v-checkbox
                       v-if="opt.description.includes('Si') "
                      :label="opt.description"
                      color="gray"
                      hide-details
                      class="mt-0 pt-0"
                      v-model="opt.value"
                      :disabled="isReadOnly"
                    ></v-checkbox>
                    <v-checkbox
                    v-if="opt.description.includes('No')"
                      :label="opt.description"
                      color="gray"
                      hide-details
                      class="mt-0 pt-0"
                      v-model="opt.value"
                      :disabled="isReadOnly"
                    ></v-checkbox>
                  </div>
                    
                </div>
              <div v-if="ch.plain">
                <da-text-field-primary 
                     :label="'Especifique:'"
                     :value="ch.text" 
                     v-model="ch.text"
                     :error="!ch.text && ch.value"
                     text
                     :disabled="isReadOnly"
                 />
              </div>
              </div>
          </div>
        </div>
       </div>
        <div v-if="px.social_media" class="px-5 py-2 border-2 border-gray-400 rounded-lg text-gray-500 mt-2">
              <span>¿Cómo nos conociste?</span>
        </div>
        <div class="flex gap-3 mt-4 ml-0">
              <v-checkbox
              v-for="option in optiones"
              :key="option.title"
              :label="option.title"
              :value="option.value"
              color="gray"
              v-model="option.value"
              hide-details
              class="mt-0 pt-0"
              :disabled="isReadOnly || isWriting"
            ></v-checkbox>
          <da-text-field-primary 
                :label="'Mencione dónde'"
                v-model="socialMediaOtherText"
                class="mt-0 pt-0 "
                text
                :disabled="isReadOnly"
            />
        </div>
      </div>
    </div>
    <da-header-title v-if="px.person.name_signature == null || px.person.name_signature == ''" :title="'Firma de Paciente'"  class="shadow-md font-medium tracking-wider"  />
    <da-header-title v-else :title="'Firma del Tutor'"  class="shadow-md font-medium tracking-wider"  />
    <div class="px-5 py-5 bg-white shadow-md">
      <div class="w-full" v-if="px.person.id_signature ">
        <img  :src="px.person.id_signature" alt="Firma" srcset="">
        <div class="w-full flex items-center justify-center border-t-2 border-gray-500">
         <span class="font-sans font-semibold tracking-wider text-gray-500 text-center">{{ px.person.name_signature ? px.person.name_signature :  px.person.fullname }}</span>
        </div>
      </div>
      <div v-else class="text-center py-5 text-gray-400">
        <span>No se ha registrado la firma del paciente</span>
      </div>
    </div>
    </div>
</template>

<script>
// import DAFamilyModel from '../../../models/patient/DAFamily.model';
// import DAFamilyGrandparentsModel from '../../../models/patient/DAFamilyGrandparents.model';
// import { SYS_PATH_FATHER, SYS_PATH_MOTHER, SYS_PATH_GRANDFATHER, SYS_PATH_GRANDMOTHER } from '../../../utils/familypathcode';

export default {
  props: {
    px: {
      type: Object,
      default: function(){return {}}
    }
  },
  data() {
        return {
            isReadOnly: true
        }
    },
  computed: {
    // mother: function() {
    //     let mother = this.px.family_parents.find(x => x.code == SYS_PATH_MOTHER);
    //     return mother ? mother : new DAFamilyModel()
    // },
    // father: function() {
    //   let father = this.px.family_parents.find(x => x.code == SYS_PATH_FATHER);
    //   return father ? father : new DAFamilyModel()
    // },
    // grandfather: function() {
    //   let grandfather = this.px.family_grandparents.find(x => x.code == SYS_PATH_GRANDFATHER);
    //   return grandfather ? grandfather : new DAFamilyGrandparentsModel()
    // },
    // grandmother: function() {
    //   let grandmother = this.px.family_grandparents.find(x => x.code == SYS_PATH_GRANDMOTHER);
    //   return grandmother ? grandmother : new DAFamilyGrandparentsModel()
    // },
    // hasBrothers: function() {
    //   return this.px.family_brothers.total > 0 ? false : true
    // }
    optiones() {
      if (this.px.social_media && Array.isArray(this.px.social_media.inputSelected)) {
        return [
          {
            title: "Facebook",
            value: this.px.social_media.inputSelected.includes("Facebook"),
            ...this.px
          },
          {
            title: "Twitter",
            value: this.px.social_media.inputSelected.includes("Twitter"),
            ...this.px
          },
          {
            title: "Otro",
            value: this.px.social_media.inputSelected.includes("Otro"),
            ...this.px
          }
        ];
      } else {
        return [];
      }
    },
    socialMediaOtherText() {
      const otherText = this.optiones.find(option => option.title === 'Otro') || {};
      return otherText.social_media?.text || 'Sin especificar';
    },
  },
  mounted() {
  },
  methods: {
}
}
</script>

<style scoped>

.v-text-field>.v-input__control>.v-input__slot>.v-text-field__slot>input {
    color: red;
}

</style>