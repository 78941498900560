<template>
    <div v-if="px.allergy.has_allergy" class="sm:h-28 md:h-20 w-full rounded-lg shadow-lg px-2 py-2 animate-fade-in-left red darken-3" color="">
        <div class="flex justify-start items-center align-middle h-full gap-4">

            <div class="grid grid-flow-row w-full">
                <div>
                    <div class="grid sm:grid-cols-1 md:grid-cols-1 m-2 gap-2">
                        <div class="sm:col-start-1 sm:col-end-2 md:col-start-1 md:col-end-10">
                            <span class="text-white font-sans text-base tracking-wider md:text-left">Alergias</span>
                        </div>
                        <div class="sm:col-start-1 sm:col-end-2 md:col-start-1 md:col-end-13">
                            <span class="font-sans tracking-wider text-sm text-white">{{ px.allergy.description ? px.allergy.description : '' }}</span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="flex justify-end align-top items-start h-full" >
                <v-icon class="w-6 h-6" color="white">mdi-alert-circle-outline</v-icon>
            </div>
        </div>
    </div>
    <div v-else>
        <div v-if="isVisibleNotAllergy" class="flex justify-start items-center align-middle h-full gap-4 red darken-3 rounded-md shadow-md animate-fade-in-left">
            <div class="grid grid-flow-row w-max">
                <div>
                    <div class="grid sm:grid-cols-1 md:grid-cols-1 m-2 gap-2">
                        <div class="sm:col-start-1 sm:col-end-2 md:col-start-1 md:col-end-13 -mt-1">
                            <span class="font-sans tracking-wider sm:text-sm text-white font-semibold">Recuerda preguntar al paciente sobre posibles alergias y actualizar el expediente.</span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="flex justify-end align-top items-start h-full " >
                <v-icon class="w-6 h-6 mr-2 mt-2 " color="white">mdi-alert-circle-outline</v-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        px: {
        type: Object,
        default: function(){return {}}
        }
  },
  data: function() {
    return {
        icons: ['mdi-square-edit'],
        transparent: 'rgba(255, 255, 255)',
        isVisibleNotAllergy: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.isVisibleNotAllergy = false;
    }, 7000);
  }

}
</script>